/* src/AnimatedText.css */
@keyframes colorChange {
    0% { color: #ff0000; }
    25% { color: #00ff00; }
    50% { color: #0000ff; }
    75% { color: #ffff00; }
    100% { color: #ffffff; }
  }
  
  .animated-text {
    animation: colorChange 5s infinite;
  }
  